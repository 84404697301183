import React from 'react';
import Link from 'next/link';
import { CountriesList } from '@castera:_manual_import/ui-country';

export default function Home() {
  return (
    <div className="container mx-auto">
      v2
      <h1 className="text-4xl font-bold">Countries list</h1>
      <button
        type="button"
        onClick={() => {
          throw new Error('Sentry Frontend Error');
        }}
      >
        Throw error here
      </button>
      <CountriesList Link={Link} />
    </div>
  );
}
